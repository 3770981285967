.ck-content.gabarito {
    color: #4dbc8e;
}

.ck-content.respostaErrada {
    color: #f15a22;
}

.gabarito.MuiRadio-colorPrimary.Mui-disabled {
    color: #4dbc8e;
}

.respostaErrada.MuiRadio-colorPrimary.Mui-disabled {
    color: #f15a22;
}

.MuiIcon-colorAction {
    color: #fbab18;
}

.enunciadoQuestao {
    margin-bottom: 2rem;
    color: #606161;

}

.enunciadoQuestao h2 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1.6rem;
}

.respostaQuestao {
    margin-bottom: 2rem;
    color: #606161;

}

.questionEditor {
    border-bottom: 1px solid #fff;
    transition: 0s border-bottom;
}

.questionEditor:hover {
    border-bottom: 1.5px solid #00bcd4;
    transition: 0.2s;
 }

 .questaoTipo {
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
    margin-bottom: 1rem;
}

#gabaritoLabel {
    display: flex;
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    color: #606161;
    font-size: small;
}

.grupoBotoes {
    text-align: center;
    margin-top: 1rem;
    min-width: 300px;
}

.MuiButton-root	 {
    display: flex;
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    font-size: small;
}

@media(min-width: 700px) {
    .MuiButtonGroup-root {
        resize: none;
    }

    .questaoTipo {
        text-align: right;
        align-items: right;
        align-self: right;
        align-content: right;
    }

    #gabarito {
        display: flex;
        padding-top: 2rem;
        padding-left: 2rem;
        color: #606161;
    }
}

#loginScreen, #resetScreen {
    background-image: url("../../assets/images/odisseia-background-blue.png");
    background-color: #2d14f4;
    background-size: 100vw;
}

@media (max-width: 1000px) {
    #loginScreen, #resetScreen {
        background-image: url("../../assets/images/odisseia-background-blue-phone.png");
        background-size: 100vw;
        background-color: #2d14f4;
    }   
}


.optionSection {
    transition: 0s background-color;
}

.optionSection:hover {
    background-color: #eeeeee;
    transition: 0.2s;
}

.correctOption {
    color: #00af2c;
    transition: 0s color background-color;
}

.correctOption:hover {
    color: #017227;
    background-color: #baf3d0;
    transition: 0.2s;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Regular.ttf") format("ttf"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  font-family: Nunito, sans-serif;
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #606161;
  height: 100vh;
  margin: 0;
  padding: 0;
  --ck-custom-border: none;
  --ck-color-focus-border: transparent;
  --ck-color-shadow-inner: none;
}

/* --- Cabeçalhos --- */
.heading-page {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #606161;
}

.subtitle-page {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #606161;
}

.subtitle-student {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  color: #606161;
}

/* --- Botões --- */
.create-button {
  margin-top: 0.9375rem;
  text-align: center;
}

.group-buttons {
  text-align: center;
  min-width: 18.75rem;
}

.button {
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  #studentPageContainer {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}

#mostrarEnunciadoOpcao {
  font-size: 0.9rem;
}

.MuiTabs-flexContainer {
  background-color: #fff;
}

.MuiToolbar-gutters {
  padding-right: 0;
}

#iframDiv {
  overflow: "auto!important";
  -webkit-overflow-scrolling: "touch!important";
}

.ck.ck-editor {
  max-width: 500px;
}